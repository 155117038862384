import { Link } from "gatsby";
import React from "react";
import { useState, useEffect, useRef } from "react";
import FeaturedProjectPreview from "./featured-project-preview";
import { motion, useMotionValue } from "framer-motion";

import * as styles from "./project-preview-slider.module.css";



function ProjectPreviewSlider(props) {

  const buttonVariants = {
    inactive: {
      opacity: 0.5
    },
    active: {
      opacity: 1
    }

  }

  const scrollRef = useRef(null)

  const sliderVariants = {}

  for(let i = 0; i < 9; i++) {
    let shiftPercentage = String((100 / -9) * i) + "%"
    sliderVariants[i] = {x: shiftPercentage}
  }





  const [focus, setFocus] = useState(0);



  return (
    <div className={styles.root}>
      <motion.ul ref={scrollRef} className={styles.slider}
     
      variants={sliderVariants}
      animate={ String(focus)}
      transition={{ type: "linear"}}>
        {props.nodes &&
          props.nodes.map((node, i) => (
            <li key={node.id}>
              <FeaturedProjectPreview {...node} i={i} focus={focus} setFocus={setFocus}/>
            </li>
          ))}
      </motion.ul>
      <section>
        <ul className={styles.sliderPagination}>
          
          <li><motion.span variants={buttonVariants} initial="active" animate={ focus == 0 ? "active": "inactive" } class="material-icons" onClick={() =>  setFocus(0)}>diamond</motion.span></li>
          <li><motion.span variants={buttonVariants} initial="inactive" animate={ focus == 1 ? "active": "inactive" } class="material-icons" onClick={() =>  setFocus(1)}>diamond</motion.span></li>
          <li><motion.span variants={buttonVariants} initial="inactive" animate={ focus == 2 ? "active": "inactive" } class="material-icons" onClick={() =>  setFocus(2)}>diamond</motion.span></li>

          <li><motion.span variants={buttonVariants} initial="inactive" animate={ focus == 3 ? "active": "inactive" } class="material-icons" onClick={() =>  setFocus(3)}>diamond</motion.span></li>
          <li><motion.span variants={buttonVariants} initial="inactive" animate={ focus == 4 ? "active": "inactive" } class="material-icons" onClick={() =>  setFocus(4)}>diamond</motion.span></li>
          <li><motion.span variants={buttonVariants} initial="inactive" animate={ focus == 5 ? "active": "inactive" } class="material-icons" onClick={() =>  setFocus(5)}>diamond</motion.span></li>

          <li><motion.span variants={buttonVariants} initial="inactive" animate={ focus == 6 ? "active": "inactive" } class="material-icons" onClick={() =>  setFocus(6)}>diamond</motion.span></li>
          <li><motion.span variants={buttonVariants} initial="inactive" animate={ focus == 7 ? "active": "inactive" } class="material-icons" onClick={() =>  setFocus(7)}>diamond</motion.span></li>
          <li><motion.span variants={buttonVariants} initial="inactive" animate={ focus == 8 ? "active": "inactive" } class="material-icons" onClick={() =>  setFocus(8)}>diamond</motion.span></li>
        </ul>
       
      </section>
    </div>
  );
}

ProjectPreviewSlider.defaultProps = {
  title: "",
  nodes: [],
};

export default ProjectPreviewSlider;


