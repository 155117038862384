import { Link } from "gatsby";
import React from "react";
import { cn, buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import BlockContent from "./block-content";

import { useState, useEffect, useRef } from "react"

import ReactPlayer from 'react-player'

import * as styles from "./featured-project-preview.module.css";
import { responsiveTitle3 } from "./typography.module.css";
import { motion, useMotionValue } from "framer-motion";

function FeaturedProjectPreview(props) {

  

  const focus = props.focus;
  const setFocus = props.setFocus;



  function handleVideoEnd() {
    if (focus == 8) {
      setFocus(0);
    } else {
      setFocus(focus + 1);
    }
  }

  const vidRef = useRef(null);
  



  return (

    <motion.div className={styles.root}>
    <ReactPlayer width="100%" height="100%" className={styles.player} url={props.previewVideo} muted={true} playsinline={true} playing={props.i == focus ? true : false} onEnded={handleVideoEnd}/>

      <div className={styles.info}>
        <h3 className={styles.title}>{props.title}</h3>
        <p className={styles.client}>{props.client}</p>
      </div>

      
      {props._rawExcerpt && (
        <div className={styles.excerpt}>
          <BlockContent blocks={props._rawExcerpt} />
        </div>
      )}
    </motion.div>
  );
}

export default FeaturedProjectPreview;
