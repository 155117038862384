// extracted by mini-css-extract-plugin
export var materialIcons = "featured-project-preview-module--material-icons--nkntQ";
export var root = "featured-project-preview-module--root--3MiJs";
export var info = "featured-project-preview-module--info--j9YUt";
export var title = "featured-project-preview-module--title--QmNwm";
export var client = "featured-project-preview-module--client--T34zV";
export var previewVideo = "featured-project-preview-module--previewVideo--gnywn";
export var leadMediaThumb = "featured-project-preview-module--leadMediaThumb--H+Ul5";
export var excerpt = "featured-project-preview-module--excerpt--2KRck";
export var playerWrapper = "featured-project-preview-module--playerWrapper--LeLia";
export var player = "featured-project-preview-module--player--e5Uaa";